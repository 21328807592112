








































import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"
import {State, namespace} from "vuex-class"

import {StoreMutation} from "lib/types/vuex"
import {MeubelParticipant} from "store/participant/types"
import {Insurance} from "store/insurance/types"

import LinkButton from "components/button/LinkButton.vue"
import Notice from "components/supplemental/Notice.vue"

const routing = namespace("routing")

@Component({
	components: {
		LinkButton,
		Notice
	}
})

export default class InsuranceTable extends Vue {
	@State participant!: MeubelParticipant
	@Prop({type: Array, required: true }) data!: Array<Insurance>

	@routing.Mutation selectPolicyId!: StoreMutation<string>

	constructUrl(url: string) {
		return !process.env.SERVER ?
		`${url}?person=${this.participant.firstName ? this.participant.firstName.toLowerCase() : ""}`
		: url
	}

	detailPolicy(id: string) {
		this.selectPolicyId(id)
		window.location.href = this.constructUrl("polisdetail.html")
	}

	expiredPolicies() {
		return this.data.filter(x => x.status === "Expirerend")
	}
}
