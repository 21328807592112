


































import Vue from "vue"
import Component from "vue-class-component"
import { State, namespace } from "vuex-class"

import {StoreMutation} from "lib/types/vuex"
import { MeubelParticipant } from "store/participant/types"
import { InsuranceState } from "store/insurance/types"
import { Message as Msg } from "store/communication/types"

import LinkButton from "components/button/LinkButton.vue"
import MessageBox from "custom/MessageBox.vue"
import MessageList from "custom/MessageList.vue"
import Page from "custom/Page.vue"
import InsuranceTable from "custom/InsuranceTable.vue"
import Card from "components/panel/Card.vue"
import Message from "components/widget/Message.vue"

const communication = namespace("communication")
const routing = namespace("routing")

@Component({
	components: {
		Page,
		InsuranceTable,
		Card,
		Message,
		MessageBox,
		MessageList,
		LinkButton
	}
})
export default class Index extends Vue {
	@State participant!: MeubelParticipant
	@State insurance!: InsuranceState

	@communication.Getter messages!: Array<Msg>
	@routing.Mutation selectMessageId!: StoreMutation<string>

	directActions = [
		{
			url: "Wijzig uw emailadres"
		},
		{
			url: "Wijzig mijn woonadres"
		},
		{
			url: "Neem contact op met a.s.r"
		}
	]

	constructUrl(url: string) {
		return !process.env.SERVER
		? `${url}?person=${this.participant.firstName}`.toLowerCase()
		: url
	}

	detailMessage(id?: string) {
		id ? this.selectMessageId(id) : window.location.href = this.constructUrl("messagedetail.html")
		window.location.href = this.constructUrl("messagedetail.html")
	}

	get firstMessage(): Array<Msg> {
		return this.messages.slice(0, 1)
	}
}
