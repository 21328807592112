
import "whatwg-fetch"
import Index from "pages/Index.vue"
import store from "store/index"
import Vue from "vue"

import options from "i18n/nl_NL"
import i18n from "lib/i18n/install"

Vue.use(i18n, options)

/* tslint:disable:no-unused-expression */
new Vue({
	components: {
		Index
	},
	el: "#app",
	store,
	template: '<div id="app"><Index v-cloak></Index></div>'
})
